<template>
  <div>
    <v-card v-if="userDetails">
      <v-layout>
        <v-card-title>My Workspaces</v-card-title>
        <v-spacer></v-spacer>
        <v-card-subtitle class="font-weight-black">{{userDetails.email}}</v-card-subtitle>
        <v-btn rounded outlined class="mt-3" @click="$store.commit('logout')"> Logout </v-btn>
      </v-layout>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-card v-for="(workspace, index) in userDetails.tenants" :key="index" class="mx-auto" max-width="344" outlined>
              <v-card-text>
                <div class="text-overline mb-4">
                  {{workspace.tenant}}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-switch inset color="success" label="Is default" class="mt-2 pt-2" v-model="workspace.isdefault"></v-switch>
                <v-spacer></v-spacer>
                <v-btn outlined rounded text @click="loginhandler(workspace.tenant)"> Login </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created () {
    this.$store.commit('restoreAuthDetails')
  },
  mounted () {
    var defaultTenant = this.userDetails.tenants.find(x => x.isdefault)
    if (defaultTenant) this.loginhandler(defaultTenant.tenant)
  },
  methods: {
    loginhandler (tenant) {
      tenant = String(tenant).toLowerCase().replace(/\s/g, '')
      this.$_execute('post', `${tenant}/login`, this.loginForm)
        .then(({ data }) => {
          this.$store.commit('login', data)
          this.$socket.emit('login', data.user)
          this.$root.$emit('snackbar', { message: 'Logged In Successfully', color: 'success' })
        })
    }
  }
}
</script>

